<template>
	<el-form :model="form" label-width="105px" class="form">
		<div class="subTit"><i class="el-icon-caret-bottom" />网站基础信息</div>
		<div class="center">
			<el-form-item label="标题:">
				<el-input v-model="form.seotitle" />
				<p class="seo-tips">
					浏览器标题用于告知搜索引擎这个网页的主要内容，是搜索引擎判断一个网页内容权重的主要参考信息之一。<br />设置技巧：网站名称_地域+服务介绍/产品介绍，一般不超过80个字符。如：XX_企业建站，XX建站-极速网站建设，一键制作高端企业网站等。
				</p>
			</el-form-item>
			<el-form-item label="关键词:">
				<el-input v-model="form.keywords" />
				<p class="seo-tips">
					关键词是搜索引擎判断网站与什么内容有关联的第一切入点，搜索引擎在搜索某个词时显示该网页，设置好关键词是第一步。<br />设置技巧：从地域+公司名称以及地域+网站业务这2方面入手，一般不超过100字符。如：广州XX建筑加固公司，XX文化策划等。
				</p>
			</el-form-item>
			<el-form-item label="描述:">
				<el-input type="textarea" :rows="3" v-model="form.description" />
				<p class="seo-tips">网站描述是指在百度输入关键词后，在搜索结果下面的一段描述，好的描述可以帮助用户更方便地从搜索结果中判断你的网页内容是否符合要求，一般不超过200个字符。</p>
			</el-form-item>
		</div>
		<div class="submit-btn" @click="handleSubmit">保存</div>
	</el-form>
</template>

<script>
	export default {
		data() {
			return {
				form: {
					seotitle: '',
					keywords: '',
					description: ''
				}
			}
		},
		created() {
			this.$axios.post('/getSellerLanmuContent', {
				lm_id: 0
			}).then(data => {
				for (let i in this.form) {
					if (data[i]) {
						this.form[i] = data[i]
					}
				}
			})
		},
		methods: {
			handleSubmit() {
				this.form.lm_id = 0
				this.form.seller_id = this.$store.state.SELLER_ID
				this.$axios.post('/setSellerLanmuContent', this.form).then(data => {
					if (data && data.code == 400005) {
						this.$message.error(data.msg)
						return
					}
					this.$message.success('设置成功')
				})
			}
		}
	}
</script>

<style scoped>
	.seo-tips {
		font-size: 12px;
		color: #999999;
		line-height: 19px;
		margin-top: 13px;
	}
</style>
